import cx from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { Button, Container, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';

import EmailPassProvider from '../../components/Login/EmailPassProvider';
import SetPasswordProvider from '../../components/Login/SetPasswordProvider';
import MetaProvider from '../../components/MetaProvider';
import { useAuth } from '../../hooks/useAuth';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(5),
    boxShadow: '0 25px 25px rgb(0 0 0 / 30%)',
    width: '100%',
  },
  logo: {
    margin: '0 auto',
    marginBottom: theme.spacing(4),
  },
  providerButtons: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(5),
    width: '75%',
  },
  providerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  providerGoogle: {
    backgroundColor: 'white',
  },
  providerEmail: {
    backgroundColor: 'white',
  },
  providerIconWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    maxWidth: 20,
  },
  providerIcon: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  formDesc: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

export type AuthStates =
  | 'select-provider'
  | 'use-email-provider'
  | 'forgot-password'
  | 'set-password';

function LoginPage(): JSX.Element {
  const r = useRouter();
  const classes = useStyles({});
  const auth = useAuth();
  const [authState, setAuthState] = useState<AuthStates>('select-provider');
  const [error, setError] = useState('');

  useEffect(() => {
    if (auth.user) {
      if (auth?.user?.role === 'super-admin' || auth?.user?.role === 'admin') {
        r.push('/forms');
      } else {
        setError('You do not have the appropriate permissions');
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (r?.query?.mode === 'resetPassword') {
      setAuthState('set-password');
    }

    if (r?.query?.error === 'not_authorized') {
      setError(`You are not authorized to perform the previous operation and have been logged out.`);
    }
  }, [r.query]);

  // if (auth.loadingUser) {
  //   return null;
  // }

  function handleGoToStep(step: AuthStates) {
    setAuthState(step);
  }

  function handleGoogleSignin(e) {
    e.preventDefault();
    auth.signinWithGoogle();
  }

  function handleShowEmailLogin(e) {
    e.preventDefault();
    setAuthState('use-email-provider');
  }

  function renderProviderSelection() {
    return (
      <div className={classes.root}>
        <div className={classes.formDesc}>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </div>
        <div className={classes.providerButtons}>
          <Button
            className={cx(classes.providerButton, classes.providerGoogle)}
            onClick={handleGoogleSignin}
            variant="contained"
          >
            <span className={classes.providerIconWrapper}>
              <img
                src="/img/icons/google.svg"
                className={classes.providerIcon}
              />
            </span>
            <span>Sign in with Google</span>
          </Button>
          <Button
            className={cx(classes.providerButton, classes.providerEmail)}
            onClick={handleShowEmailLogin}
            variant="contained"
          >
            <span className={classes.providerIconWrapper}>
              <EmailIcon color="secondary" className={classes.providerIcon} />
            </span>
            <span>Sign in with email</span>
          </Button>
        </div>
      </div>
    );
  }

  function renderProviderSelect() {
    switch (authState) {
      case 'select-provider':
        return renderProviderSelection();

      case 'set-password':
        return <SetPasswordProvider goToAuthStep={handleGoToStep} />;

      case 'use-email-provider':
        return <EmailPassProvider goToAuthStep={handleGoToStep} />;

      default:
        return null;
    }
  }

  return (
    <>
      <Helmet>
        <style>{`body { background: linear-gradient(166deg, #7f7fd5, #86a8e7, #91eae4) no-repeat; height: 100vh; }`}</style>
      </Helmet>
      <CssBaseline />
      <MetaProvider
        meta={{
          noIndex: true,
          title: 'Login',
        }}
      />
      <Container maxWidth="xs" className={classes.container}>
        <Paper className={classes.paper}>
            <img
              src="/img/fired-up-logo-blue.svg"
              className={classes.logo}
            />
          <Divider />

          {renderProviderSelect()}
          </Paper>
      </Container>
    </>
  );
}

export default LoginPage;
