import { firebase } from '../../../lib/firebase';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { Button, Divider, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AuthStates } from '../../../pages/login';

const useStyles = makeStyles(theme => ({
  rootForm: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(5),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  },
  formDesc: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type Steps =
  | 'enter-email'
  | 'enter-password'
  | 'create-account'
  | 'forgot-password';

interface EmailPassProviderProps {
  goToAuthStep: (step: AuthStates) => void;
}

const initialValues = {
  email_address: '',
  family_name: '',
  given_name: '',
  password: '',
};

function EmailPassProvider(props: EmailPassProviderProps): JSX.Element {
  const classes = useStyles({});
  const r = useRouter();
  const [step, setStep] = useState<Steps>('enter-email');
  const [values, setValues] = useState(initialValues);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  function handleFormChange(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }

  function handleCancel() {
    setValues(initialValues);
    props.goToAuthStep('select-provider');
  }

  async function handleSignIn() {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(values.email_address, values.password);
      r.push('/users');
    } catch (err) {
      console.log(err);
      setError('Invalid password or account');
    }
  }

  async function handleForgotPassword() {
    try {
      await firebase.auth().sendPasswordResetEmail(values.email_address);
    } catch (err) {
      console.log(err);
    } finally {
      setSuccess(
        `If an account exists with this email address, you'll shortly receive a link to set or reset your password.`
      );
    }
  }

  function renderPasswordStep() {
    return (
      <div>
        <form className={classes.rootForm} onChange={handleFormChange}>
          <div className={classes.formDesc}>
            <Typography variant="h6">Sign in with email</Typography>
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </div>
          <TextField
            label="Email"
            name="email_address"
            value={values.email_address}
            type="email"
          />
          <TextField
            label="Password"
            name="password"
            value={values.password}
            type="password"
          />
          <div className={classes.buttonHolder}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSignIn} variant="contained">
              Sign in
            </Button>
          </div>
        </form>
        <Divider className={classes.divider} />
        <Button onClick={() => setStep('forgot-password')} size="small">
          First login or forgot password?
        </Button>
      </div>
    );
  }

  function renderForgotPasswordStep() {
    return (
      <div>
        <form className={classes.rootForm} onChange={handleFormChange}>
          <div className={classes.formDesc}>
            <Typography variant="h6">Set or retrieve your password</Typography>
            <Typography variant="body2">
              {`We'll send you a link to set or reset your password`}
            </Typography>
          </div>
          <TextField
            label="Email"
            name="email_address"
            value={values.email_address}
            type="email"
          />
          {success ? (
            <p>{success}</p>
          ) : (
            <div className={classes.buttonHolder}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleForgotPassword} variant="contained">
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
    );
  }

  function renderCreateAccountStep() {
    return (
      <form className={classes.rootForm} onChange={handleFormChange}>
        <div className={classes.formDesc}>
          <Typography variant="h6">Sign in</Typography>
        </div>
        <TextField
          label="Email"
          name="email_address"
          value={values.email_address}
          type="email"
        />
        <TextField
          label="First name"
          name="given_name"
          value={values.given_name}
        />
        <TextField
          label="Last name"
          name="family_name"
          value={values.family_name}
        />
        <TextField
          label="Choose a password"
          name="password"
          value={values.password}
          type="password"
        />

        <div className={classes.buttonHolder}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained">Sign up</Button>
        </div>
      </form>
    );
  }

  switch (step) {
    case 'enter-email':
      return renderPasswordStep();

    case 'forgot-password':
      return renderForgotPasswordStep();

    case 'create-account':
      return renderCreateAccountStep();

    default:
      return null;
      break;
  }
}

export default EmailPassProvider;
