import { firebase } from '../../../lib/firebase';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AuthStates } from '../../../pages/login';

const PASSWORD_RESET_MESSAGE = `If an account exists with this email address, you'll shortly receive a link to set or reset your password.`;

const useStyles = makeStyles(theme => ({
  rootForm: {
    width: '100%',
    textAlign: 'left',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  },
  formDesc: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

type Steps =
  | 'enter-email'
  | 'enter-password'
  | 'create-account'
  | 'forgot-password';

interface SetPasswordProviderProps {
  goToAuthStep: (step: AuthStates) => void;
}

function SetPasswordProvider(props: SetPasswordProviderProps) {
  const classes = useStyles({});
  const r = useRouter();
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [disabled, setDisabled] = useState(false);

  const resetCode = r?.query?.oobCode as string;

  useEffect(() => {
    if (!resetCode) {
      setDisabled(true);
      setError('Invalid password reset code supplied');
    } else {
      firebase
        .auth()
        .verifyPasswordResetCode(resetCode)
        .then(email_address => {
          setEmailAddress(email_address);
        })
        .catch(err => {
          if (err.code === 'auth/expired-action-code') {
            return setError('This code has expired');
          }

          return setError('Invalid password reset code supplied');
        });
    }
  }, []);

  function handleFormChange(e) {
    setPassword(e.target.value);
  }

  async function handleSetPassword() {
    setError('');
    firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        setSuccess('Password successfully updated!');
      })
      .catch(err => {
        let message;
        switch (err?.code) {
          case 'auth/weak-password':
            message = err.message;
            break;

          default:
            message = 'There was an error updating your password';
            break;
        }
        setError(message);
      });
  }

  function handleCancel() {
    setPassword('');
    setEmailAddress('');
    window.location.href = '/login';
  }

  function renderButton() {
    if (success) {
      return (
        <Button
          onClick={() => props.goToAuthStep('use-email-provider')}
          variant="contained"
        >
          Proceed to Login
        </Button>
      );
    }

    return (
      <div className={classes.buttonHolder}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          disabled={!password || disabled}
          onClick={handleSetPassword}
          variant="contained"
        >
          Set password
        </Button>
      </div>
    );
  }

  return (
    <div>
      <form className={classes.rootForm} onChange={handleFormChange}>
        <div className={classes.formDesc}>
          <Typography variant="h6">Set your password</Typography>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          {success && <Typography variant="body2">{success}</Typography>}
        </div>
        <TextField
          disabled={true}
          label="Email address"
          name="email_address"
          value={emailAddress}
        />
        <TextField
          disabled={!!success || disabled}
          label="Password"
          name="password"
          value={password}
          type="password"
        />
        <div className={classes.buttonHolder}>{renderButton()}</div>
      </form>
    </div>
  );
}

export default SetPasswordProvider;
